<template>
  <div class="app-container">
    <div class="outer">
      <div class="model-title">
        结算单信息
      </div>
      <DetailContent>
        <div>
          <span v-select>结算单号：</span><p>{{ info.info.settlement_sn }}</p>
        </div>
        <div>
          <span v-select>结算单创建时间：</span><p>{{ info.info.cdate }}</p>
        </div>
        <div>
          <span
            v-select
            class="danger"
          >结算状态：</span><p>{{ info.info.cn_state }}</p>
        </div>
        <div>
          <span v-select>结算金额：</span><p>{{ info.info.settlement_price }}</p>
        </div>
        <div>
          <span v-select>

            <el-tooltip
              class="item"
              effect="dark"
              content="结算单中所有结算商品的实付金额"
              placement="top-start"
            >
              <span>
                销售额
                <i class="el-icon-question" />：
              </span>
            </el-tooltip>
          </span><p>{{ info.info.pay_price }}</p>
        </div>
        <div>
          <span v-select>退款金额：</span><p>{{ info.info.return_price }}</p>
        </div>
        <div>
          <span v-select>平台补贴总金额：</span><p>{{ info.info.actual_coupon_price_fee }}</p>
        </div>
        <div>
          <span v-select>手续费：</span><p>{{ info.info.service_fee }}</p>
        </div>
      </DetailContent>
      <div class="model-title">
        销售单
      </div>
      <div class="salesInfo">
        订单数：{{ orderNum }}，商品数：{{ goodsNum }}，结算金额：{{ info.info.settlement_price }}
      </div>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        highlight-current-row
        :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8', 'color': '#333'}"
        :row-class-name="tableRowClassName"
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column
          prop="rec_id"
          label="序号"
          width="80"
        />
        <el-table-column
          prop="purchase_sn"
          label="销售单号"
          width="150"
        />
        <el-table-column
          prop="order_sn"
          label="订单号"
          width="150"
        />
        <el-table-column
          prop="cn_settlement_status"
          label="结算状态"
          width="115"
        />
        <el-table-column
          prop="goods_name"
          label="商品名称"
          width="200"
        />
        <el-table-column
          prop="spec_key_name"
          label="规格名称"
          width="140"
        />
        <el-table-column
          prop="goods_num"
          label="数量"
          width="80"
        />
        <el-table-column
          prop="cost_price"
          label="单价"
          width="100"
        />
        <el-table-column
          prop="sales_price"
          label="销售总价"
          width="100"
        />
        <el-table-column
          prop="actual_coupon_price_suppliers_fee"
          width="100"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="买家支付时使用商家优惠券抵扣的金额"
              placement="top"
            >
              <p>优惠券<i class="el-icon-question"></i></p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="actual_coupon_price_fee"
          width="140"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="平台补贴的金额，补贴方式有优惠券等"
              placement="top"
            >
              <p>平台补金额<i class="el-icon-question"></i></p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="actual_shipping_fee"
          width="140"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="买家在交易过程中购买该商品所支付的运费"
              placement="top"
            >
              <p>实付运费<i class="el-icon-question"></i></p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="actual_price_fee"
          width="140"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="买家支付的金额"
              placement="top"
            >
              <p>商品实付金额<i class="el-icon-question"></i></p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="pay_service_fee"
          width="140"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="支付手续费=商品实付金额*手续费费率，支付宝、微信手续费费率为0.6%"
              placement="top"
            >
              <p>支付手续费<i class="el-icon-question"></i></p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="支付方式"
          prop="pay_name"
          width="100"
        />
        <el-table-column
          label="退款金额"
          width="180"
          prop="return_price"
        />
        <el-table-column
          prop="return_coupon"
          label="平台补贴退款"
          width="100"
        />
        <el-table-column
          prop="return_service_fee"
          label="退款金额手续费"
          width="140"
        />
        <el-table-column
          prop="settlement_price"
          width="140"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="商品结算金额=商品实付金额-退款金额-（支付手续费-退款手续费）+平台补贴金额"
              placement="top"
            >
              <p>商品结算金额<i class="el-icon-question"></i></p>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import DetailContent from '@/components/common/DetailContent'
export default {
  components: {
    DetailContent
  },
  data() {
    return {
      role: '', // 角色
      tableData: [], // 分页
      type: 1, // 1 采购单  2 售后单
      info: {
        info: {},
        suppliers: {},
        log: {}
      },
      count: 0,
      orderNum: 0,
      goodsNum: 0,
      loading: false,
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      }
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail() {
      this.loading = true
      this.$axios.get(this.$api.closeOrder.close_detail, {
        params: {
          settlement_sn: this.$route.query.settlementSn,
          page: this.pagination.currentPage,
          // limit: this.pagination.nowPageSize
          limit: 99999
        }
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.info.info = r.data.goods_settlement
          this.tableData = r.data.goods_sales.lists
          this.goodsNum = r.data.goods_num
          this.orderNum = r.data.order_num
        }
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .closeDetail p{
    margin:20px 0;
  }
  .closeDetail p span{
    display: inline-block;
    width:110px;
    text-align: right;
    margin-right:5px;
    color:#48576a;
    font-weight: 600;
  }
  .closeDetail p i{
    display: inline-block;
    min-width:220px;
  }
  .refuse{
    width:80%;
    padding: 10px;
    border:1px solid #48576A;
    border-radius:7px;
    margin: 0 auto;
    resize:none;
    height:100px;
    display: block;
  }
  .model-title {
    font-size: 16px;
    color: #0D0202;
    font-weight: 600;
    line-height: 80px;
  }
  .danger {
    font-weight: 600;
  }
</style>
