var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c("div", { staticClass: "model-title" }, [_vm._v(" 结算单信息 ")]),
        _c("DetailContent", [
          _c("div", [
            _c(
              "span",
              { directives: [{ name: "select", rawName: "v-select" }] },
              [_vm._v("结算单号：")]
            ),
            _c("p", [_vm._v(_vm._s(_vm.info.info.settlement_sn))]),
          ]),
          _c("div", [
            _c(
              "span",
              { directives: [{ name: "select", rawName: "v-select" }] },
              [_vm._v("结算单创建时间：")]
            ),
            _c("p", [_vm._v(_vm._s(_vm.info.info.cdate))]),
          ]),
          _c("div", [
            _c(
              "span",
              {
                directives: [{ name: "select", rawName: "v-select" }],
                staticClass: "danger",
              },
              [_vm._v("结算状态：")]
            ),
            _c("p", [_vm._v(_vm._s(_vm.info.info.cn_state))]),
          ]),
          _c("div", [
            _c(
              "span",
              { directives: [{ name: "select", rawName: "v-select" }] },
              [_vm._v("结算金额：")]
            ),
            _c("p", [_vm._v(_vm._s(_vm.info.info.settlement_price))]),
          ]),
          _c("div", [
            _c(
              "span",
              { directives: [{ name: "select", rawName: "v-select" }] },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "结算单中所有结算商品的实付金额",
                      placement: "top-start",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(" 销售额 "),
                      _c("i", { staticClass: "el-icon-question" }),
                      _vm._v("： "),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("p", [_vm._v(_vm._s(_vm.info.info.pay_price))]),
          ]),
          _c("div", [
            _c(
              "span",
              { directives: [{ name: "select", rawName: "v-select" }] },
              [_vm._v("退款金额：")]
            ),
            _c("p", [_vm._v(_vm._s(_vm.info.info.return_price))]),
          ]),
          _c("div", [
            _c(
              "span",
              { directives: [{ name: "select", rawName: "v-select" }] },
              [_vm._v("平台补贴总金额：")]
            ),
            _c("p", [_vm._v(_vm._s(_vm.info.info.actual_coupon_price_fee))]),
          ]),
          _c("div", [
            _c(
              "span",
              { directives: [{ name: "select", rawName: "v-select" }] },
              [_vm._v("手续费：")]
            ),
            _c("p", [_vm._v(_vm._s(_vm.info.info.service_fee))]),
          ]),
        ]),
        _c("div", { staticClass: "model-title" }, [_vm._v(" 销售单 ")]),
        _c("div", { staticClass: "salesInfo" }, [
          _vm._v(
            " 订单数：" +
              _vm._s(_vm.orderNum) +
              "，商品数：" +
              _vm._s(_vm.goodsNum) +
              "，结算金额：" +
              _vm._s(_vm.info.info.settlement_price) +
              " "
          ),
        ]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%", "margin-top": "20px" },
            attrs: {
              data: _vm.tableData,
              border: "",
              "highlight-current-row": "",
              "header-cell-style": {
                "font-weight": "bold",
                background: "#F0F2F8",
                color: "#333",
              },
              "row-class-name": _vm.tableRowClassName,
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "rec_id", label: "序号", width: "80" },
            }),
            _c("el-table-column", {
              attrs: { prop: "purchase_sn", label: "销售单号", width: "150" },
            }),
            _c("el-table-column", {
              attrs: { prop: "order_sn", label: "订单号", width: "150" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "cn_settlement_status",
                label: "结算状态",
                width: "115",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "goods_name", label: "商品名称", width: "200" },
            }),
            _c("el-table-column", {
              attrs: { prop: "spec_key_name", label: "规格名称", width: "140" },
            }),
            _c("el-table-column", {
              attrs: { prop: "goods_num", label: "数量", width: "80" },
            }),
            _c("el-table-column", {
              attrs: { prop: "cost_price", label: "单价", width: "100" },
            }),
            _c("el-table-column", {
              attrs: { prop: "sales_price", label: "销售总价", width: "100" },
            }),
            _c(
              "el-table-column",
              {
                attrs: {
                  prop: "actual_coupon_price_suppliers_fee",
                  width: "100",
                },
              },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "买家支付时使用商家优惠券抵扣的金额",
                          placement: "top",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v("优惠券"),
                          _c("i", { staticClass: "el-icon-question" }),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "el-table-column",
              { attrs: { prop: "actual_coupon_price_fee", width: "140" } },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "平台补贴的金额，补贴方式有优惠券等",
                          placement: "top",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v("平台补金额"),
                          _c("i", { staticClass: "el-icon-question" }),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "el-table-column",
              { attrs: { prop: "actual_shipping_fee", width: "140" } },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "买家在交易过程中购买该商品所支付的运费",
                          placement: "top",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v("实付运费"),
                          _c("i", { staticClass: "el-icon-question" }),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "el-table-column",
              { attrs: { prop: "actual_price_fee", width: "140" } },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "买家支付的金额",
                          placement: "top",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v("商品实付金额"),
                          _c("i", { staticClass: "el-icon-question" }),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "el-table-column",
              { attrs: { prop: "pay_service_fee", width: "140" } },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            "支付手续费=商品实付金额*手续费费率，支付宝、微信手续费费率为0.6%",
                          placement: "top",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v("支付手续费"),
                          _c("i", { staticClass: "el-icon-question" }),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("el-table-column", {
              attrs: { label: "支付方式", prop: "pay_name", width: "100" },
            }),
            _c("el-table-column", {
              attrs: { label: "退款金额", width: "180", prop: "return_price" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "return_coupon",
                label: "平台补贴退款",
                width: "100",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "return_service_fee",
                label: "退款金额手续费",
                width: "140",
              },
            }),
            _c(
              "el-table-column",
              { attrs: { prop: "settlement_price", width: "140" } },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            "商品结算金额=商品实付金额-退款金额-（支付手续费-退款手续费）+平台补贴金额",
                          placement: "top",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v("商品结算金额"),
                          _c("i", { staticClass: "el-icon-question" }),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }